
.transition.home span{
    font-size: 2rem;
}

/* HERO */

#hero{
    padding-top: 11rem;
    padding-bottom: 0;
}

#hero .container{
    display: grid;
    gap: 5rem;
}

#hero h2{
    font-size: 15vw;
    font-weight: 700;
}

#hero .body-link{
    margin-top: 1.3rem;
}

.left-content p{
    font-size: 1.5rem;
}

.right-content{
    position: relative;
}

.right-content p{
    font-size: 1.5rem;
}

.hero-circle{
position: absolute;
right: 0%;
top: 1rem;
z-index: -10;
display: block;
width: auto;
}

#hero ul{
    display: flex;
    gap: 4rem;
    padding: 1rem 0;
    background-color: #3b3b3b;
    margin-top: 8rem;
    gap: 5%;
    justify-content: center;
}

#hero ul li{
    display: flex;
    align-items: center;
}

#hero ul li img{
    width: 3rem;
}

.locomotive-list{
    display: flex;
    justify-content: center;
    gap: 4rem;
}

.locomotive-list div{
    display: flex;
    gap: 4rem;
    justify-content: center;
}

.icon-list{
    overflow: hidden;
}

/* FEATURED PROJECTS */
#featured-projects ul{
    display: grid;
    margin-top: 3rem;
}


#featured-projects ul li a{
    display: grid;
    gap: .7rem;
    position: relative;
}

#featured-projects h2{
    margin-bottom: 1rem;
}

#featured-projects h3{
    text-transform: uppercase;
}



#featured-projects li .arrow-box{
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    background-color: white;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}


#featured-projects .img-box{
    position: relative;
    border-radius: 1.1rem;
}

#featured-projects .img-box img{
    display: block;
}

#featured-projects .arrow-box img{
    width: 1.5rem;
}

#featured-projects .img-box .arrow-box img:last-of-type{
    position: absolute;
    top: 200%;
    left: 50%;
    transform: translate(-50%, -50%);
}


#featured-projects .animation-complete  .img-box > img{
    transition: scale .4s;
}


 #featured-projects .animation-complete .img-box:hover > img{
    scale: 1.1!important;
    transition: .25s;
}

.arrow-box img{
    transition: .3s;
}

#featured-projects .img-box:hover .arrow-box img:last-of-type{
    top: 50%!important;
}

#featured-projects .img-box:hover .arrow-box img:first-of-type{
    transform: translateY(-200%)!important;
}

#featured-projects .bottom-content{
    margin-top: 1.2rem;
}

#featured-projects .body-link{
    border: 1px solid grey;
    background: transparent ;
    will-change: transform;
    color: black;
    font-weight: 500;
    font-size: 1.08rem;
    padding-inline: 1.4rem;
    transition: .3s;
    padding-block: .3rem;
}

#featured-projects .body-link:hover{
    rotate: 5deg!important;
    background-color: black;
    color: white;
}

.project-details{
    display: flex;
    gap: 2rem;
    justify-content: unset!important;
    align-items: center;
    position: relative;
}


.char{
    font-weight: 600;
}


.project-details h3,
.project-details span{
    order: 2;
    font-size: 2rem!important;
}

.project-details h3,
.project-details span{
    line-height: 1;
}

#featured-projects li img:not(.arrow-box img){
    border-radius: 1.1rem;
    aspect-ratio: 3/1.7;
    object-fit: cover;
}

#featured-projects ul{
    display: grid;
    gap: 2rem;
}

#featured-projects a:hover{
    transition: 300ms;
}

#featured-projects .line-container{
    overflow: visible!important;
}

#featured-projects .project-details span{
    
    position: absolute;
    transform: translateY(100%);
    text-transform: uppercase;
    display: block;
}

#featured-projects li{
    position: relative;
}

#featured-projects .char{
    font-weight: 700;
}

.horizontal-bar{
    width: 100%;
    height: 1px;
    background-color: #ababab;
    transform-origin: left;
    position: relative;
    z-index: 9;
    margin-bottom: .75rem;
}

.mini-title{
    color: #ababab;
    margin-bottom: 3rem;
    font-size: 1.1rem;
}

.gradient-line{
    position: absolute;
    bottom: -1px;
    transform: scaleX(0);
    transform-origin: left;
    left: 0;
    background: rgb(151,255,218);
    background: linear-gradient(33deg, rgba(151,255,218,1) 27%, rgba(204,148,224,1) 100%);
    z-index: 9999;
    height: 1.5px;
    width: 100%;
}

#featured-projects .bottom-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#featured-projects .bottom-content .body-link{
    margin: 0;
    color: black;
    font-weight: 600;
    border-color: black;
}

#featured-projects .bottom-content .body-link:hover{
    color: white;
    background-color: black!important;
}

/* TABS SECTION */

#tabs-section{
    background-color: #1E1E1E;
    color: white;
}

#tabs-section .main-wrapper{
    display: grid;
    flex-direction: column;
    gap: 10%;
}

#tabs-section p,
#tabs-section span{
    color: white;

}

#tabs-section .overflow-box{
    margin-bottom: 3rem;
}

#tabs-section .overflow-box h2{
    color: white;
}

#tabs-section .content-box{
    gap: 1rem;
    display: grid;
    display: none;
    animation-fill-mode: both;    
}

#tabs-section .content-box.active{
    display: grid;
    animation: fade-in .4s;
}
@keyframes fade-in {
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
    
}

#tabs-section .content-box.hidden{
    animation: fadeOut .4s;
}

@keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }


#tabs-section .body-link{
    background: transparent;
    color: white;
    border: 2px solid  rgba(151,255,218,1);
}

#tabs-section .tabs span{
    font-size: 1.2rem;
    font-weight: 700;
    color: grey;
}

#tabs-section .green-dot{
    color: rgba(151,255,218,1);
}

.tabs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}

.tabs button{
    border-bottom: 3px solid grey;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    outline: 0;
    border-left: none;
    border-top: 0;
    border-right: 0;
    cursor: pointer;
    align-items: center;
}

.tabs button.active{
    border-image-slice: 1;
    opacity: 1;
    border-image-source: linear-gradient(33deg, rgba(151,255,218,1) 17%, rgba(204,148,224,1) 100%);
}

.tabs button img{
    width: 1.7rem;
    opacity: 0;
    position: relative;
    transition: .25s .25s;
    transform: translateX(-10px);
}

.tabs button.active img{
    opacity: 1;
    right: 0;
    transform: translateX(0);
}

.tab-title{
    pointer-events: none;
}

#tabs-section .tabs button.active span{
    color: white;
    transition: 300ms 200ms;
}

#tabs-section .tabs button.active img{
    opacity: 1;
}

@media screen and (min-width: 520px){
    #tabs-section .tabs .tab-title{
        font-size: 2rem;
    }

    #featured-projects h3,
    #featured-projects .project-details span{
        font-size: 3rem!important;
    }
}

/* Breakpoints */

@media screen and (min-width: 720px) {
    .transition.home span{
        font-size: 3rem;
    }

    #hero .container{
        grid-template-columns: 1fr 1.2fr;
        gap: 0rem;
        align-items: center;
    }

    #hero h2{
        font-size: 3.5rem;
    }

 

    #tabs-section .main-wrapper{
        grid-template-columns: 1fr 1.3fr;
    }

    #tabs-section .content-wrapper{
        order: 2;
    }

    .tabs{
        grid-template-columns: none;
    }

    .tabs button img{
        width: 2.3rem;
    }

    .content-box .body-link{
        justify-self: end;
    }

    #featured-projects ul{
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }


}

@media screen and (min-width: 920px) {

    #hero p{
        font-size: 1.8rem!important;
    }

    .hero-circle{
        top: 4.5rem;
    }

    #hero h2{
        font-size: 6rem;
    }

    .right-content p{
        font-size: 1.7rem;
        padding-right: 15%;
        text-wrap: balance;
    }

    #featured-projects a{
        transition: 1s;
    }

    #featured-projects a:hover{
        border-color: rgb(92, 91, 91);
    }

   

    #featured-projects a h3,
    #featured-projects .project-details span{
        font-size: 1.75rem!important;
        font-weight: 600;
        text-transform: uppercase;
    }

    #featured-projects a:hover p{
        color: #000000;
    }

    body #featured-projects .arrow-box{
        height: 3.5rem;
        width: 3.5rem;
    }
  
}

@media screen and (min-width: 1200px) {
    #hero .container{
        grid-template-columns: 1fr 1.5fr;
    }

    .hero-circle{
        top: 2rem;
        right: 15%;
    }

    
}

@media screen and (min-width: 1450px) {
    #hero{
        padding-top: 12rem;
    }

    #hero h2{
        font-size: 7rem;
    }

    #hero ul{
        margin-top: 8rem;
    }
}