*{
    padding: 0;
    margin: 0;
    font-family: 'NeueHaas';
    box-sizing: border-box;
    list-style: none;
    text-transform: none;
    text-decoration: none;
    color: #333;
}


/* SCROLLBAR */
::-webkit-scrollbar {
    width: 13px;
    margin: 3px; /* Width of the scrollbar */
    overflow: visible;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
 /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background: #1f1f1f;
    border-radius: .5rem;
    overflow: visible;
    border-inline: 1px solid white;
    margin: 3px; /* Color of the scrollbar thumb */
}

#root{
    position: relative;
}

#shadow-host-companion{
    display: none;
}

body{
    overflow-x: hidden;
}

.black-heading-gradient{
    background: linear-gradient(6deg,rgb(161 248 229) 0%,rgb(0 0 0) 44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.white-heading-gradient{
    background: linear-gradient(0deg,rgb(161 248 229) 0%,rgb(255,255,255) 65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.transition-heading-gradient{
    background: linear-gradient(6deg,rgb(191 81 207) 0%,rgb(255,255,255) 58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



/* LOADER STLYES */

.loader{
    width: 100%;
    height: 100vh;
    background-color: rgb(3, 3, 3);
    position: fixed;
    z-index: 999999;
    transition: .7s cubic-bezier(0.85, 0, 0.15, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: .5rem;
}

.transition{
    display: block;
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #1c1e1e;
    width: 100dvw;
    height: 100dvh;
    font-size: 3rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }


.transition.green{
    background-color: var(--verde);
    z-index: 9999;
}

.transition.violet{
    background-color: rgb(233, 171, 255);
    z-index: 999;
}

#hero h2 .char{
    font-weight: 700;
}

.loader.closed{
    transform: translateY(-100%);
    pointer-events: none;
}

.loader-title{
    font-weight: bolder;
    font-size: 3rem;
    display: block;
    color: white;
    text-align: center;
    transform: translateY(100%);
    background: linear-gradient(to right, #97FFDA 10%, #CC94E0 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: slideIn .9s .2s  cubic-bezier(0.85, 0, 0.15, 1) forwards;
    line-height: 1;
}

.transition span{
    line-height: 1;
}

.transition.home span{
    font-size: 10rem !important;
}

.transition.home .overflow-box{
    text-transform: uppercase;
    display: flex;
}


@keyframes slideIn {
    0%{
        transform: translateY(100%);
    }

    100%{
        transform: translateY(0);
    }
}

/* GLOBAL STYLES */

h1{
    font-size: 0;
}

h2{
    font-size: 2.7rem;
    font-weight: 700;
}

.subtitle{
    font-size: 1.2rem;
}

p,span,li{
    line-height: 1.5;
    font-weight: 600;
    word-spacing: 2px;
}

img{
    width: 100%;
    display: block;
}

main{
    z-index: 9;
    background-color: white;
    position: relative;
}

header nav{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.overflow-box{
    overflow: hidden;
}

.body-link{
    background: rgb(151,255,218);
    background: linear-gradient(33deg, rgba(151,255,218,1) 27%, rgba(204,148,224,1) 100%);
    padding: .5rem 1rem;
    border-radius: 20px;
    font-size: 1.2rem;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 1rem;
    width: fit-content;
}

.green-dot{
    color: var(--verde);
    line-height: 0;
    background: none;
}

.container{
    padding-inline: 1.5rem;
}

.container{
    margin: auto;
}

/* .locomotive-container{
    position: fixed;
} */

.slide-link {
    overflow: hidden;
    display: block;
    position: relative
}

.slide-link:hover span {
    transform: translateY(-100%)
}

.origin-left{
    transform-origin: left;
}

.slide-link span {
    display: block;
    transition: .5s cubic-bezier(.215,.61,.355,1)
}

.slide-link span:nth-child(2) {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(100%)
}

.slide-link:hover span:nth-child(1) {
    transform: translateY(-100%)
}

.slide-link:hover span:nth-child(2) {
    transform: translateY(0)
}


p,span,h1,h2,h3,h4,h5,li{
    color: #1E1E1E;
}

section{
    padding-block: 5rem;
}

p, h3{
    font-size: 1.25rem;
}



  .transition span{
    display: block;
    font-weight: bold;
    
  }

.cursor{
    width: 3rem;
    height: 3rem;
    position: fixed;
}

html.lenis {
    height: auto;
  }
  
  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }
  
  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }
  
  .lenis.lenis-stopped {
    overflow: hidden;
  }
  
  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }

  #contacto{
    background-position-x: -23%;
    background-position-y: 99%;
    background-image: url(./img/footer-gradient.svg);
    background-repeat: no-repeat;
  }



@media screen and (min-width: 760px) {
    .container{
        max-width: 800px ;
    }

    .subtitle{
        width: 50%;
    }

    p, h3{
        font-size: 1.5rem!important;
    }
}

@media screen and (min-width: 900px) {
    .container{
        max-width: 930px;
    }

    h2{
        font-size: 5rem;
        line-height: 1.2;
    }

    .subtitle{
        font-size: 1.4rem;
    }  
}

@media screen and (min-width: 1150px) {
    .container{
        max-width: 1200px;
    }


}

@media screen and (min-width: 1400px) {
    .container{
        max-width: 1500px;
    }

     .transition span{
        font-size: 3rem;
    }
}




