#single-project #hero h2{
    font-weight: 600;
    margin-bottom: 1rem;
    line-height: 1.2;
}

.back-home-link span{
    text-transform: uppercase;
    font-weight: bolder;
}

#single-project #hero{
    padding-top: 10rem;
    padding-bottom: 4rem;
    background-color: white;
    z-index: 99999999999;
    position: relative;
}

#single-project #hero .container{
    gap: 1rem;
}

 #single-project #hero p{
    font-size: 1.55rem!important;
    color: rgb(78, 78, 78);
} 

#horizontal{
    padding-inline: 1.5rem;
    display: grid;
    gap: 1.4rem;
    background-color: white;
    z-index: 9;
}


#horizontal img{
    width: 100%;
    border-radius: 2rem;
    height: 100%;
    border: 1px solid rgb(195, 195, 195);
    object-fit: cover;

}

#horizontal figure:nth-child(even) img{
    width: 100%;
    border-radius: 2rem;
    height: 100%;
    object-fit: cover;
}

#project-images{
    padding-top: 0;
    background-color: white;
    z-index: 9;
    position: relative;
}

#project-details{
    background-color: #1c1e1e;
    z-index: 9;
    position: relative;
}

#project-details *{
    color: white;
}

#project-details h2{
    font-size: 15px;
    font-weight: 500;
    margin-top: 1rem;
    color: lightgrey;
    margin-bottom: 3rem;
}

#project-details h3{
    font-size: 2rem;
}

#project-details ul{
    margin-top: 3rem;
}

#project-details h4{
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 1.7rem;
}

#project-details li{
    padding-block: 2rem;
    border-top: .7px solid lightgrey;
    border-bottom: .7px solid lightgrey;
}

@media screen and (min-width: 520px){
    
}

/* Breakpoints */

@media screen and (min-width: 760px) {
    #single-project #hero .container {
        grid-template-columns: 1fr 1fr;
        justify-items: end;
        align-items: end;
    }

    #single-project #hero{
        padding-bottom: 0;
        padding-top: 11rem;
    }

    #horizontal{
        flex-direction: row!important;
    }

    #horizontal{
        display: flex;
        width: fit-content!important;
        flex-direction: column;
        height: 100vh!important;
        align-items: center;
        padding-inline: 2rem 4rem!important;
        
    }
    
    #horizontal figure{
        width: 85vw;
        height: 100%;
        height: 90%;
    }

    #single-project #project-details h3{
        font-size: 2.7rem!important;
    }

    #project-details ul{
        margin: 0;
    }

   #project-details h2{
    font-size: 1.1rem;
   }

    #project-details .content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6%;
    }

    #project-details h3{
        position: sticky;
        top: 17rem;
    }
}

@media screen and (min-width: 920px) {
    #single-project #project-details h3{
        font-size: 3rem!important;
    }

    #project-details .content{
        gap: 10%;
    }

    #project-details h4{
        font-size: 2.6rem;
    }

    #project-details li{
        padding-block: 4rem;
        border-width: .7px;
    }
}

@media screen and (min-width: 1200px) {
  #horizontal figure{
        width: 65vw;
    }
    
}

@media screen and (min-width: 1450px) {
    
}